<template>
  <div class="pb-12">
    <div class="flex justify-start items-center">
      <div class="md:w-6/12 flex justify-start">
        <img
          class="w-max h-32 ml-28 mt-0 hidden md:block"
          src="@/assets/images/dataservice-logo.svg"
          alt="dataservice-logo"
        />
      </div>
      <div class="md:w-5/12">
        <HeaderGlobal
          titlemenu1="Services"
          :to1="'/browseservicedirectory'"
          :titlemenu2="`Veterinarian Profile: ${
            businessProfile.businessName || businessProfile.practiceName
          }`"
          class="pl-4"
        />
      </div>
    </div>
    <div class="w-11/12 md:w-10/12 mx-auto mt-12 flex flex-col md:flex-row">
      <div class="w-full md:w-6/12 text-subheaderText text-left">
        <div class="flex items-center">
          <h3 class="font-proximaMedium text-2xl">
            {{ businessProfile.businessName || businessProfile.practiceName }}
          </h3>
          <div class="ml-10 bg-white rounded-md px-2 py-1">
            <HeartIcon
              v-if="businessProfile.is_friends_fav"
              width="24"
              height="24"
              class="cursor-pointer"
              @click.native="clickFavorite"
              iconColor="#ff0c00"
            />
            <HeartIcon
              v-else
              width="24"
              height="24"
              class="cursor-pointer"
              @click.native="clickFavorite"
              iconColor="#0AC0CB"
            />
          </div>
        </div>
        <h4 class="w-7/12 py-4 font-proximaLight text-xl">
          {{
            `${businessProfile.streetAddress} ${businessProfile.city}, ${businessProfile.state} ${businessProfile.postalCode}, ${businessProfile.country}`
          }}
          <br />
          {{ `Tel. ${businessProfile.phoneNo}` }}
        </h4>
        <ul>
          <li
            class="py-1 flex"
            v-for="(data, i) in businessProfile.workingHours"
            :key="i"
          >
            <div class="w-10">{{ data.day }}:</div>
            <span>{{ data.working_start && data.working_finish }}</span>
          </li>
        </ul>
        <!-- -------------- not in MVP ----------------- -->
        <!--   <div class="mt-8 flex items-center">
          <div
            class="w-max p-6 mr-10 text-primary flex flex-col items-center border border-primary rounded-md bg-white cursor-pointer"
          >
            <PhoneIconVue iconColor="#0AC0CB" width="32" height="32" />
            <span>Call Now</span>
          </div>
          <div
            class="w-max p-6 flex flex-col items-center border border-primary rounded-md text-white bg-primary cursor-pointer"
          >
            <MessagIconVue iconColor="#fafafa" width="32" height="32" />
            <span>Message</span>
          </div>
        </div> -->
        <!--      <div v-if="center">
          <vl-map
            :load-tiles-while-animating="true"
            :load-tiles-while-interacting="true"
            data-projection="EPSG:4326"
            style="margin-top: 30px; height: 320px; width: 480px"
          >
            <vl-view
              :zoom.sync="zoom"
              :center.sync="center"
              :rotation.sync="rotation"
            ></vl-view>

            <vl-geoloc @update:position="geolocPosition = $event">
              <template slot-scope="geoloc">
                <vl-feature v-if="geoloc.position" id="position-feature">
                  <vl-geom-point :coordinates="center"></vl-geom-point>
                  <vl-style-box>
                    <vl-style-icon
                      style="width: 1px; height: 1px"
                      :src="require('../../assets/images/marker-map-icon.png')"
                      :scale="0.08"
                      :anchor="[0.5, 1]"
                    ></vl-style-icon>
                    <vl-style>
                    
                    </vl-style>
                  </vl-style-box>
                </vl-feature>
              </template>
            </vl-geoloc>

            <vl-layer-tile id="osm">
              <vl-source-osm></vl-source-osm>
            </vl-layer-tile>
          </vl-map>
        </div> -->
      </div>
      <div class="w-full md:w-6/12 mt-20 md:mt-0 text-subheaderText">
        <div class="mb-2 md:w-5/12 ml-20 flex items-center">
          <label class="container"
            >English/ Multilingual Support
            <input
              type="checkbox"
              :checked="businessProfile.english_multilang"
              v-model="businessProfile.english_multilang"
              :disabled="true"
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="mb-2 md:w-6/12 ml-20 flex items-center">
          <label class="container"
            >Special Equipment: 24-hr Holter ECG
            <input
              type="checkbox"
              :checked="businessProfile.service_24_7"
              v-model="businessProfile.service_24_7"
              :disabled="true"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <!--  <img
          class="my-6 rounded-md"
          style="width: 500px; height: 250px"
          src="@/assets/images/servicedirectoryicons/Artist-Services.png"
        /> -->
        <!--  <vue-image-slider
          class="my-6"
          :intervalVal="3000"
          :height="320"
          :width="480"
          :images="imageUrls"
        /> -->
        <ImageSlider :images="imageUrls" />
        <!-- to add after !!!!!!!!! -->
        <!-- <div>
          <ul>
            <li
              class="text-left my-4 flex"
              v-for="(data, i) in ratings"
              :key="i"
            >
              <div class="w-8/12 md:w-6/12">{{ data.name }}</div>
              <VueStarRating
                class="flex justify-center"
                :star-size="16"
                :rating="data.rating"
                :read-only="true"
                :show-rating="false"
                :increment="0.5"
                :max-rating="3"
              />
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <!-- -------------- not in MVP ----------------- -->
    <!--   <div
      class="w-11/12 md:w-10/12 mx-auto mt-12 grid gap-4 md:grid-cols-2 md:grid-rows-2"
    >
      <LatestReviewComponentVue
        v-for="(data, i) in latestReviews"
        :key="i"
        :latestReviews="data"
      />
    </div> -->
  </div>
</template>

<script>
import HeaderGlobal from "../header/HeaderGlobal.vue";
import DataserviceLogo from "../DataserviceLogo.vue";
import MessagIconVue from "../icons/MessagIcon.vue";
import PhoneIconVue from "../icons/PhoneIcon.vue";
import CheckIconVue from "../icons/CheckIcon.vue";
import HeartIcon from "../icons/HeartIcon.vue";
import LatestReviewComponentVue from "./LatestReviewComponent.vue";
import ImageSlider from "./ImageSlider.vue";

import VueStarRating from "vue-star-rating";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    HeaderGlobal,
    DataserviceLogo,
    MessagIconVue,
    PhoneIconVue,
    CheckIconVue,
    HeartIcon,
    LatestReviewComponentVue,
    VueStarRating,
    ImageSlider,
  },
  data() {
    return {
      favoriteToggle: false,
      zoom: 15,
      lon: 0,
      lat: 0,
      center: null,
      rotation: 0,
      geolocPosition: null,
      schedule: [
        { name: "Mon", time: "9.00-17.00 & 18.00-20.30" },
        { name: "Tue", time: "9.00-17.00" },
        { name: "Wed", time: "9.00-17.00" },
        { name: "Thu", time: "9.00-17.00" },
        { name: "Fri", time: "9.00-17.00" },
        { name: "Sat", time: "9.00-17.00" },
        { name: "Sun", time: "9.00-17.00" },
      ],
      profile_url: require("@/assets/images/servicedirectoryicons/service_picture_1.jpg"),
      imageUrls: [
        require("@/assets/images/servicedirectoryicons/service_picture_1.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_2.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_3.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_4.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_5.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_6.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_7.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_8.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_9.jpg"),
        require("@/assets/images/servicedirectoryicons/service_picture_10.jpg"),
      ],
      ratings: [
        { name: "Communication Style", rating: 3 },
        { name: "Good with fearful animals", rating: 2.5 },
        { name: "Breadth of Knowledge", rating: 2.5 },
        { name: "Openness to owner input", rating: 3 },
        { name: "Politically Neutral", rating: 3 },
        { name: "Price", rating: 2 },
      ],
      latestReviews: [
        {
          id: 1,
          name: "Alex",
          date: "24/7/22 , 2 days",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis accusamus distinctio molestiae cupiditate, harum repudiandae aliquid.",
          image: "pp-xl.png",
        },
        {
          id: 2,
          name: "Jazmin",
          date: "24/7/22 , 2 days",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis accusamus distinctio molestiae cupiditate, harum repudiandae aliquid.",
          image: "pp-xl.png",
        },
        {
          id: 3,
          name: "Vazques",
          date: "24/7/22 , 2 days",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis accusamus distinctio molestiae cupiditate, harum repudiandae aliquid.",
          image: "pp-xl.png",
        },
        {
          id: 4,
          name: "Albert",
          date: "24/7/22 , 2 days",
          comment:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nobis accusamus distinctio molestiae cupiditate, harum repudiandae aliquid.",
          image: "pp-xl.png",
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      businessProfile: "serviceprovider/getSP",
    }),

    favoriteColor() {
      if (this.favoriteToggle) {
        return "#ff0c00";
      } else {
        return "#0AC0CB";
      }
    },
  },
  watch: {
    businessProfile: function () {
      this.center = [
        this.businessProfile.longitude,
        this.businessProfile.latitude,
      ];
      this.geolocPosition = this.center;
    },
  },
  async created() {
    console.log("create");
    this.getBusinessProfile(this.$route.params["id"]);

    //this.lon = this.businessProfile.longitude;

    //this.lat = this.businessProfile.latitude; //52.517519;
    //this.center = [this.lon, this.lat];
    //console.log("---------", this.lon, this.lat);
  },
  methods: {
    ...mapActions({
      getBusinessProfile: "serviceprovider/getBusinessProfile",
    }),
    clickFavorite() {
      this.favoriteToggle = !this.favoriteToggle;
    },
  },
};
</script>
<style scoped>
/* The container */
.container {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 15px;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #0ac0cb;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 7px;
  top: 4px;
  width: 5px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>
